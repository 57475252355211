import React, { useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import firebase from 'firebase/compat/app';
import {user_id} from './App';



const db = firebase.firestore();

const Profile = ({profiler}) => {

    console.log('user_id')
    console.log(user_id)
   
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [inputFields, setInputField] = useState([])


    return (
    
<div>
        <Modal show={show} onHide={handleClose} class="modal-dialog modal-dialog-centered modal-dialog-scrollable"> 
        <Modal.Header closeButton>
          <Modal.Title>profiler.id</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>
        <Form.Select aria-label="Default select example">
      <option>What do you identify as?</option>
      <option value="1">Male</option>
      <option value="2">Female</option>
      {/* <option value="3">Three</option> */}
        </Form.Select>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

</div>
    );

   
};

export default Profile;
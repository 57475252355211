import {Form, Button, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col, Nav} from 'react-bootstrap'
import React, {useState, useEffect} from "react";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import SubmitSurvey from './SubmitSurvey';
import 'bootstrap/dist/css/bootstrap.min.css'
import firebase from 'firebase/compat/app';
import Survey from './Survey';
import Presurvey from './Presurvey';
import Profile from './Profile';
import { QuerySnapshot, runTransaction } from "firebase/firestore";
import { getFirestore, doc, getDoc } from "firebase/firestore"
import {GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import {authentication} from './firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { TwitterShareButton, TwitterIcon,} from "react-share";
import BarChart from "./BarChart";
import Chart from "react-apexcharts";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Modal from 'react-bootstrap/Modal';
import mixpanel from 'mixpanel-browser';

// This website is a joke, but because we take the joke seriously
// we would like you to nominate emotionally intelligent people around you,
// to answer Petty Surveys.

const db = firebase.firestore();
export var data_ex, statuses_ex, user_id, login, sex_ex, rel_stage, 
ex_option_a, ex_option_b, ex_option_c, ex_option_d, ex_background
var  message = "your question? e.g what's your advice for me?"
export var showDialogue = false
var modal_text
export function changeShowDialogue(){
  showDialogue = false
}

function App() {
  const [feeds, setFeeds] = useState([]);
  const [prefeeds, setPreSurvey] = useState([]);
  const [survey, uploadSurvey] = useState([]);
  const [profiler, setProfile] = useState([]);
  const [data2,setData2]=useState("1");
  const [data3,setData3]=useState("Married");
  const [data,setData]=useState(null);
  const [background,setBackground]=useState(null);
  const [option_a, setoptionA]=useState(null);
  const [option_b, setoptionB]=useState(null);
  const [option_c, setoptionC]=useState(null);
  const [option_d, setoptionD]=useState(null);
  const [app_statuses, setStatus] = useState(0);
  var [user_idd, setUser] = useState(0);
  const [sex, setSex]=useState(null);
  const [stage, setStage]=useState(null);
  const [show, setShow] = useState(false);
  const [faqs, setShowFaq] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  mixpanel.init("ff34d02d72a629eebde8aa8650dc6023", {
  });
  const auth = getAuth();

  sex_ex = sex

  user_id = user_idd
  statuses_ex = app_statuses
  data_ex = data
  rel_stage = data3
  // rel_stage = stage

  ex_option_a = option_a
  ex_option_b = option_b
  ex_option_c = option_c
  ex_option_d = option_d
  ex_background = background

  function getData(val)
  {
    setData(val.target.value)
  }

  function getBackground(val)
  {
    setBackground(val.target.value)
  }

  function getData2(val2)
  {
    setData2(val2.target.value)
  }

  function getData3(val3)
  {
    setData3(val3.target.value)
  }

  // get data on page load***for voters
//   useEffect(() => {
//     db.collection("surveys")
//     .get()
//     .then(querySnapshot => {
//      setFeeds(querySnapshot.docs.map(doc => doc.data())); 
//      getStatus()
//   });
// }, [])


// useEffect(() => { ***
//   onAuthStateChanged(auth, (user) => {
//     if (user) {
//       setUser(user.uid)
//     }
//   });
// }, []);

// useEffect(() => {
//   getSex()
// }, [data2]);

function fetchSurvey(){
  // setPreSurvey([])
  db.collection("surveys")
  .get()
  .then(querySnapshot => {
   setFeeds(querySnapshot.docs.map(doc => doc.data())); 
});
}

useEffect(() => {
  mixpanel.track('landpage', {
    time: new Date().toLocaleString()
  } )
},[])

function getOptionA(val){
        setoptionA(val.target.value)
    }

    function getOptionB(val){
        setoptionB(val.target.value)
    }

    function getOptionC(val){
        setoptionC(val.target.value)
    }

    function getOptionD(val){
        setoptionD(val.target.value)
    }
 

  function completeSurvey(){
  //  const survey_quest = data
    console.log('data')
    console.log(data)
    if(data == null | background == null | option_a == null | option_b == null |option_c == null | option_d == null){
      mixpanel.track('fill in all error', {
        data: data,
        background: background,
        option_a: option_a,
        option_b: option_b,
        option_c: option_c,
        option_d: option_d,
        time: new Date().toLocaleString()
      } )
      alert('please fill in all fields')
    }else{
      mixpanel.track('move to payment', {
        data: data,
        background: background,
        option_a: option_a,
        option_b: option_b,
        option_c: option_c,
        option_d: option_d,
        time: new Date().toLocaleString()
      } )
      showDialogue = true
      uploadSurvey(data)
    }
    
  }

  function getStatus(){
    db.collection("users").doc(user_id)
    .get()
    .then((doc) => {
      if (doc.exists) {
        console.log('from')
        console.log(doc.data().status)
        setStatus(doc.data().status); 
    } else {
        console.log("No such document!");
    }
     
    });
    // setStatus(true)
}


const userExists = async ()=>{
    try {
      await runTransaction(db, async (transaction) => { 

      const docRef = doc(db, 'users', user_id);
      const docSnap = await getDoc(docRef);

          if (docSnap.exists()){
            getStatus();
          }else{
            db.collection('users').doc(user_id).set({id: user_id, bdate: 'unknown', sex: 'unknown'}, {merge: true})   
            getStatus();
          }
        });
      } catch (e) {
      // console.log("Transaction failed: ", e);
    }
  }

if (user_id=='0'){
  login = 'Login'
}else{
  login = 'Logout'
}


const signInWIthGoogle = ()=>{
    // console.log('sigining in...')
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
    .then((re)=>{
      var user_id = re.user.uid
      setUser(user_id)
      console.log(user_id);
      userExists()
    })
    .catch((error) => {
      // console.log(error);
      alert('error on login, pls refresh and try again')
    })
    // window.location.reload();
  }

  function getGroupPosts(){

    if(user_id=='0'){
      signInWIthGoogle()
      return
    }
   
  setFeeds([])
  // db.collection("surveys").where('sex', "==", sex).where('stage', "==", data3)
  db.collection("surveys")
  .get()
  .then((snapshot) => {
    if (snapshot.empty) {
      alert('Sorry no posts for your group at the moment')
      fetchSurvey()
    }else{
      setPreSurvey(snapshot.docs.map(doc => doc.data()));
    }
   });

  }

  function getProfile(){
    // setFeeds([])
    db.collection("users").doc(user_id)
    .get()
    .then((doc) => {
      if (doc.exists) {
        setProfile(doc.data()); 
     console.log('profile')
     console.log(profiler)
        console.log("Document data:", doc.data());
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
     
    });
  }

  function getSex(){
    // getData2()
    if (data2 == 2){
      console.log(data2)
      // boys
      // message = "What would you like to know?"
      setSex('Ladies')
    }
    if (data2 == 1){
      console.log(data2)
      // girls
      // message = "What would you like to know?"
      setSex('Men')
    }
    // if (data2 == 0){
    //   console.log(data2)
    //   // question
    //   alert('Please choose sex of participants')
    //   return
    // }
  }


  function getStage(){

    if (data3 == 3){
      console.log(data3)
      setStage('Dating')
    }
    if (data3 == 2){
      console.log(data3)
      setStage('Engaged')
    }
    if (data3 == 1){
      console.log(data3)
      setStage('Married')
    }
  }

 function getTextfromModal(){
  modal_text = background
  handleClose()
 }

 function getFaq(){
  setShowFaq(!faqs)
  // setS
  mixpanel.track('FAQ button was clicked', {
    time: new Date().toLocaleString()
  })
}

  return (
    <div className="App">
      <div class="container" >
        <nav className="ml-auto" class="navbar navbar-expand navbar-light" style={{"padding":"0px", margin:0}} >
        <a href="#" onClick={fetchSurvey} class="navbar-brand" style={{"color": "#D9DAE0", margin:5}} > 💕 PettySurvey </a>
        <button
          class="navbar-toggler"
          type ="button"
          data-bs-toggle="collapse"
          data-bs-target="#toggleMobileMenu"
          aria-controls="toggleMobileMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
          >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="toggleMobileMenu">
          <ul class="navbar-nav">
            <li>
              {/* <a class="nav-link" href="#" onClick={getProfile}>Profile</a> */}
            </li>
            {/* <li>
              <a class="nav-link" style={{"color": "#D9DAE0"}} href="#" onClick={fetchSurvey}>Advice</a>
            </li> */}
            {/* <li>
              <a style={{"color": "#D2C3C0"}} class="nav-link" href="#" onClick={signInWIthGoogle} >{login}</a>
            </li> */}
            {/* <li>
              <a class="nav-link" onClick={getFaq} style={{"color": "#D9DAE0"}} href="#" >FAQ</a>
            </li>  */}
          </ul>
        </div>
          {/* <span class="navbar-text"> */}
              {/* <TwitterShareButton url={'Trouble understanding your Man? Use Male targeted surveys to learn more about how men think.'} title={'https://PettySurvey.com: '}>
                <TwitterIcon size={25} round />
              </TwitterShareButton> */}
            {/* <li>
              <a class="nav-link" style={{"color": "#D9DAE0"}} href="#" >FAQ</a>
            </li>  */}
          {/* </span> */}
        <span class="navbar-text">
            <a href="whatsapp://send?text= Just found this website https://PettySurvey.com, It let's you create men targeted surveys to learn more about how your man thinks.😉" data-action="share/whatsapp/share"><img src="https://www.freeiconspng.com/uploads/logo-whatsapp-png-free-vector-download-7.png" width="25" alt="Logo Whatsapp Png Free Vector Download" /> </a>
        </span> 

        </nav>
      </div>

  <div className="min-h-screen flex flex-col text-white">
    <main className="container mx-auto px-6 pt-16 flex-1 text-center"> 
     <Form>
     {/* <div style={{ borderRadius:20, marginTop:0}} className="text-lg md:text-2xl lg:text-3xl py-2 px-4 md:py-4 md:px-10 lg:py-6
          lg:px-12 bg-white bg-opacity-10 w-fit mx-auto mb-8 rounded-full"> */}
     {/* <h5 style={{display: 'flex',  justifyContent:'left', alignItems:'center', height:'5vh', marginLeft: 0, marginTop: 30}}>
        1,370 Surveys Answered by Men!
        </h5> */}
        {/* <h5 style={{display: 'flex',  justifyContent:'center', alignItems:'center', height:'6vh', marginLeft: 0, marginTop: 20}}>
         Get Answers to Your Most Pressing Dating Questions 💖😭💔
        </h5> 
        {/* </div> */}
        {/* <div style={{ borderRadius:20, marginTop:0}} className="text-lg md:text-2xl lg:text-3xl py-2 px-4 md:py-4 md:px-10 lg:py-6
          mb:px-12 bg-white bg-opacity-10 w-fit mx-auto mb-8 rounded-full">
            Over 1,370 Problems Analysed!
          </div>  */}
  { !faqs &&
      <Form.Group className="mb-3" controlId="formBasicEmail" style={{ margin: 10, marginTop: 10, borderRadius:0}}>

        {/* <InputGroup className="mb-3" style={{ height: 40, marginTop:0}}> */}
                {/* <Form.Select onChange={getData} style={{ marginTop: 0}}  aria-label="Default select example"> */}
                   {/* <option value="0"> What culture is she identified as? </option>
                   <option value="1"> African  </option>
                   <option value="2"> American (Black) </option>
                   <option value="2"> American (White) </option>
                   <option value="3"> Asian </option>
                   <option value="3"> Australian </option>
                   <option value="4"> Carribean </option>
                   <option value="5"> European </option>
                   <option value="5"> Indian </option>
                   <option value="6"> Latino </option>
                   <option value="6"> Middle Eastern </option> */}
                   {/* <option value="500">500 Ladies ($100) </option> */}
                   {/* <option value="1000">1000 Ladies ($200) </option> */}
                   {/* <option value="10000">10000 Ladies ($2000) </option> */}
               {/* </Form.Select> */}
                {/* </InputGroup> */}

                <div style={{ borderRadius:20, marginTop:40, marginBottom:10}} className="text-lg md:text-2xl lg:text-3xl py-2 px-4 md:py-4 md:px-10 lg:py-6
                  lg:px-12 bg-white bg-opacity-10 w-fit mx-auto mb-8 rounded-full">
                    Fill and Send out a PettySurvey to your target audience 😃
                </div>

               <InputGroup className="text-muted" style={{ height: 50, marginTop:0}}>
                    {/* <FloatingLabel
                    controlId="floatingInput"
                    label="What happened?"
                    className="mb-3"
                    > */}
                    <Form.Control 
                    as="textarea"
                        style={{ height: 70}}
                        onClick={handleShow}
                        onChange={getBackground}
                        value={modal_text}
                        placeholder="What happened?"
                        placeholderTextColor = "white"
                         />
                {/* </FloatingLabel> */}
                </InputGroup> 

                <InputGroup className="mb-3" style={{ height: 30, marginTop:15}}>
            <Form.Select className="text-muted" onChange={getData} style={{ marginTop: 10}}  aria-label="Default select example">
                {/* <option value="0"> Who should answer this survey?</option> */}
                <option  value="0"> Choose your survey question? </option>
                <option  value="Should I be worried?"> Should I be worried? </option>
                <option  value="Which do you like the best?"> Which do you like the best? </option>
                <option  value="Where did I go wrong?"> Where did I go wrong? </option>
                <option value="What should I have done?"> What should I have done? </option>
                <option  value="What should I do?"> What should I do? </option>
                <option  value="What would you have done?"> What would you have done? </option>
                {/* <option  value="Who should apologize?"> Who should apologize? </option> */}
                {/* <option  value="What would you have done?"> Should I stop trying? </option> */}

            </Form.Select>
        </InputGroup>


          <div style={{ borderRadius:20, marginTop:40, marginBottom:10}} className="text-lg md:text-2xl lg:text-3xl py-2 px-4 md:py-4 md:px-10 lg:py-6
          lg:px-12 bg-white bg-opacity-10 w-fit mx-auto mb-8 rounded-full">
             Come Up With Four Possible Answers to Your Survey Question
          </div> 
          
          <InputGroup className="text-muted" style={{ height: 40}}>
                {/* <FloatingLabel
                controlId="floatingInput"
                label="e.g you messed this one up"
                className="mb-3"
                > */}
                    <Form.Control 
                        onChange={getOptionA}
                        placeholder="Answer A" />
                    {/* <Form.Control.Feedback type="invalid">
                    your question?
                    </Form.Control.Feedback> */}
                    {/* </FloatingLabel> */}
                </InputGroup>
                

                <InputGroup className="text-muted" style={{ height: 40, marginTop:0}}>
                {/* <FloatingLabel
                    controlId="floatingInput"
                    label="e.g no big deal today, move on"
                    className="mb-3"
                    > */}
                    <Form.Control 
                        onChange={getOptionB}
                        placeholder="Answer B" />
                    {/* <Form.Control.Feedback type="invalid">
                    username?
                    </Form.Control.Feedback> */}
                    {/* </FloatingLabel> */}
                </InputGroup>

                <InputGroup className="text-muted" style={{ height: 40, marginTop:0}}>
                {/* <FloatingLabel
                    controlId="floatingInput"
                    label= "e.g he should apologise, ignore him "
                    className="mb-3"
                    > */}
                    <Form.Control 
                        onChange={getOptionC}
                        placeholder="Answer C" />
                    {/* </FloatingLabel> */}
                </InputGroup>

                <InputGroup className="text-muted" style={{ height: 40, marginTop:0}}>
                    {/* <FloatingLabel
                    controlId="floatingInput"
                    label="e.g you should apologise"
                    className="mb-3"
                    > */}
                    <Form.Control 
                        onChange={getOptionD}
                        placeholder="Answer D" />
                {/* </FloatingLabel> */}
                </InputGroup>             

          {/* <div className="mb-3">
                <Form.Check inline type="radio" label="Ask Girls" name="group1"/>
                <Form.Check inline type="radio" label="Ask Guys" name="group1"/>
          </div> */}
          <div style={{ borderRadius:20, marginTop:25}} className="text-lg md:text-2xl lg:text-3xl py-2 px-4 md:py-4 md:px-10 lg:py-6
          lg:px-12 bg-white bg-opacity-10 w-fit mx-auto mb-8 rounded-full">
            Choose Who Should Answer Your Survey Question?
          </div> 

          <InputGroup className="mb-3" style={{ height: 30, marginTop:10}}>
            <Form.Select className="text-muted" onChange={getData2} style={{ marginTop: 0}}  aria-label="Default select example">
                {/* <option value="0"> Who should answer this survey?</option> */}
                <option className="text-muted" value="1"> Men </option>
                <option  value="2"> Ladies </option>
            </Form.Select>
            <Form.Select className="text-muted" onChange={getData3} style={{ marginTop: 0}}  aria-label="Default select example">
                {/* <option value="Divorced"> Divorced </option> */}
                <option  value="Dating"> Dating </option>
                <option  value="Married"> Married </option>
                <option  value="Engaged"> Engaged </option>
                <option value="Divorced"> Divorced </option>
                <option  value="Single"> Single </option>

            </Form.Select>
        </InputGroup>

          {/* <div style={{ borderRadius:20, marginTop:0}} className="text-lg md:text-2xl lg:text-3xl py-2 px-4 md:py-4 md:px-10 lg:py-6
          lg:px-12 bg-white bg-opacity-10 w-fit mx-auto mb-8 rounded-full">
            Whose turn is it to apologize? 😭
          </div> */}

        <button type="button" style={{ marginLeft: 0, marginTop: 15, borderRadius:4, width: 125, height: 50}} className="btn btn-warning waves-effect" onClick={completeSurvey}>
          <i className="fas fa-sun pr-2" aria-hidden="true"></i>   Send Survey
        </button>

        <div style={{marginTop: 30}}>
        
          <small> <i> We are always growing our Responder database.  <a href="https://forms.gle/w8c3qFyE8JdSw44Z8">Click here </a> if you'd like to be a PettySurvey Responder or nominate someone.</i></small>
        </div>
    
    {/* modal for explanation */}
        <Modal show={show}  onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>What happened?</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              {/* <Form.Label>What happened?</Form.Label> */}
              <Form.Control placeholder="What happened?" onChange={getBackground} value={background} as="textarea" rows={5} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <button type="button" style={{ marginLeft: 0, marginTop: 15, borderRadius:4, width: 100, height: 45}} className="btn btn-warning waves-effect" onClick={getTextfromModal}>
          <i className="fas fa-sun pr-2" aria-hidden="true"></i>   Save
        </button>
  
        </Modal.Footer>
      </Modal>

      </Form.Group>
    }

      </Form>

      <React.Fragment>
        {/* <Survey feeds={feeds}/> */}
        <Presurvey prefeeds={prefeeds}/>
        <Profile profiler={profiler}/>
        <SubmitSurvey survey={survey}/>
      </React.Fragment>
    </main> 

  </div>


  {
    faqs &&
    <div>
    <div class="container" style={{ marginTop: 0, borderRadius: 0}}>
    <div style={{ borderRadius:20, marginTop:25}} className="text-lg md:text-2xl lg:text-3xl py-2 px-4 md:py-4 md:px-10 lg:py-6
          lg:px-12 bg-white bg-opacity-90 w-fit mx-auto mb-8 rounded-full">
        <div><b class="mb-1"> </b> <strong class="text-muted"> Why are surveys realer than relationship advice ? </strong></div>
            <div><b class="mb-1"> </b> <small class="text-muted"> A TroopDrop is a simple promise that a dropper will give $X to a lucky person, after an NFT sells 📈.</small></div>
            <div><b class="mb-1"> </b> <small class="text-muted"> TroopDrop.io is a p2p airdrop platorm that lets you track live airdrops 💸 from mates who recently sold NFTs. </small></div>
    </div>

    <div style={{ borderRadius:20, marginTop:25}} className="text-lg md:text-2xl lg:text-3xl py-2 px-4 md:py-4 md:px-10 lg:py-6
          lg:px-12 bg-white bg-opacity-90 w-fit mx-auto mb-8 rounded-full">
            <div style={{ marginTop: 20, borderRadius: 0}}><b class="mb-1"> </b> <strong class="text-muted"> Short coming of Survey advice? 💰 </strong></div>
            <div><b class="mb-1"> </b> <small class="text-muted"> This is 100% the Dropper's choice. Most droppers might require you prove membership to a community by preseting an NFT 🔑. We are working on supporting more ENS clubs and PFP projects </small></div>
</div>
    <div style={{ borderRadius:20, marginTop:25}} className="text-lg md:text-2xl lg:text-3xl py-2 px-4 md:py-4 md:px-10 lg:py-6
          lg:px-12 bg-white bg-opacity-90 w-fit mx-auto mb-8 rounded-full">
            <div style={{ marginTop: 20, borderRadius: 0}}><b class="mb-1"> </b> <strong class="text-muted"> Who responds to the surveys? </strong></div>
            <div><b class="mb-1"> </b> <small class="text-muted"> The first wallet on the queue at the time of sale catches the drop. </small></div>
</div>
    <div style={{ borderRadius:20, marginTop:25}} className="text-lg md:text-2xl lg:text-3xl py-2 px-4 md:py-4 md:px-10 lg:py-6
          lg:px-12 bg-white bg-opacity-90 w-fit mx-auto mb-8 rounded-full">
            <div style={{ marginTop: 20, borderRadius: 0}}><b class="mb-1"> </b> <strong class="text-muted"> ? </strong></div>
            <div><b class="mb-1"> </b> <small class="text-muted"> At the moment, only one catcher per airdrop. </small></div>
</div>
    <div style={{ borderRadius:20, marginTop:25}} className="text-lg md:text-2xl lg:text-3xl py-2 px-4 md:py-4 md:px-10 lg:py-6
          lg:px-12 bg-white bg-opacity-90 w-fit mx-auto mb-8 rounded-full">
            <div style={{ marginTop: 20, borderRadius: 0}}><b class="mb-1"> </b> <strong class="text-muted"> What are PettySurveys? </strong></div>
            <div><b class="mb-1"> </b> <small class="text-muted"> A Queue is simply a first-come-first-serve digital waiting system ⏸️, where wallets queue up to access drops. Leaving a queue gives the wallet behind you, your queue position ⏩.</small></div>
    </div>

    </div>
    </div>
  }
    </div>
  );
}

export default App;